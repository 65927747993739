body {
  font-family: "DM Sans";
}

#lang-menu {
  display: none;
}

#lang-menu-btn:focus+#lang-menu {
  display: block;
}

.main-content {
  margin-top: 60px;
}

.active {
  color: rgb(37 99 235);
  /* Tailwind's blue-600 color */
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden!important;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.map {
  height: 660px;
}

.slider-container {
  padding: 0 20px; /* Slider kenar boşlukları */
}

.slider-item {
  padding: 0 10px; /* Sağ ve sol kenar boşlukları */
}

.slick-slide > div {
  margin: 0 10px; /* Slider öğeleri arasındaki boşluk */
}

.slick-list {
  margin: 0 -10px; /* Kenar boşluklarını kaldır */
}

.slider-image {
  width: 100%;
  border-radius: 10px;
}



@media screen and (max-width: 1440px) {
  .header-container {
    max-width:100%!important;
    padding-left: 40px;
    padding-right: 40px;
  }
  .w-mac-3-5 {
    width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  .header-container {
    max-width:100%!important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .w-mac-3-5 {
    width: 100%!important;
  }
  .hidden.t {
    display: none;
  }
  .map {
    height: 300px;
    margin-bottom: 30px;
  }
  
}

@media screen and (max-width: 768px) {
  .header-container {
    max-width:100%!important;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
  }
  .map {
    height: 250px;
  }
}